import { useCartStore } from '@context/cartStore';
import { documentDetailsStore } from '@context/documentDetailsStore';
import { userStore } from '@context/userStore';
import Dropdown from '@elements/Dropdown';
import Icon from '@elements/Icon';
import logo from '@elements/Logo';
import { calculateTotalQuantity } from '@helpers/getCartQuantity';
import { getCart } from '@providers/cart';
import { performClientSearch } from '@providers/search';
import SearchBox from '@search/SearchBox';
import { buildSearchConfig } from '@search/helpers/searchConfig';
import { sortArrayByArray } from '@search/helpers/sortArrayByArray';
import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';
import MobileMenu from './MobileMenu';
import UserMobileMenu from './UserMobileMenu';

let profileMenu = {
  label: 'Eric Janssen',
  menuItems: [
    {
      id: 1,
      name: 'Mijn profiel',
      href: '/profile',
      icon: 'user',
    },
    {
      id: 2,
      name: 'Mijn organisatie',
      href: '/organization',
      icon: 'building',
    },
    {
      id: 3,
      name: 'Beheer',
      href: '/admin',
      icon: 'folder-gear',
    },
    //TODO: Implement recently viewed pages
    // {
    //   id: 4,
    //   name: 'Laatst bekeken',
    //   href: '/recent',
    //   icon: 'eye',
    // },
    {
      id: 5,
      name: 'Uitloggen',
      href: '/profile/logout',
      icon: 'power-off',
    },
  ],
};

export async function getMenuItems(field) {
  const body = buildSearchConfig({
    facets: [{ field: field, size: 99 }],
    size: '0',
    index_type: 'document',
  });
  const index_type = 'document';

  const response = await performClientSearch(body, index_type);

  if (response?.status === 200) {
    return {
      menuItems: response.data.aggregations,
    };
  } else {
    return {
      menuItems: null,
    };
  }
}

export default function Navbar() {
  const logoData = logo;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [profileBarOpen, setProfileBarOpen] = useState(false);
  const [isLoggedIn, fullName, claims] = userStore((state) => [
    state.isLoggedIn,
    state.fullName,
    state.claims,
  ]);
  // Keep track of whether app has mounted (hydration is done)
  const [hasMounted, setHasMounted] = useState(false);
  const [documentDetails, setDocumentDetails] = documentDetailsStore((state) => [
    state.documentDetails,
    state.setDocumentDetails,
  ]);

  const cartStore = useCartStore();
  const [itemsInCart, setItemsInCart] = useState(cartStore.itemsInCart);

  useEffect(() => {
    const unsubscribe = useCartStore.subscribe((state) => {
      setItemsInCart(state.itemsInCart);
    });

    return () => unsubscribe();
  }, []);

  const [menuItems, setMenuItems] = useState(profileMenu.menuItems);

  // Check if the logged in user has the appropriate claim
  function checkClaim() {
    if (
      !claims.includes('enterprise_admin') &&
      !claims.includes('organization_admin') &&
      !claims.includes('platform_admin')
    ) {
      setMenuItems(
        menuItems.filter((item) => item.name !== 'Mijn organisatie' && item.name !== 'Beheer'),
      );
    } else if (!claims.includes('enterprise_admin') && !claims.includes('organization_admin')) {
      setMenuItems(menuItems.filter((item) => item.name !== 'Mijn organisatie'));
    } else if (!claims.includes('platform_admin')) {
      setMenuItems(menuItems.filter((item) => item.name !== 'Beheer'));
    }
  }

  useEffect(() => {
    const fetchCart = async () => {
      const response = await getCart();

      if (!response || response.status !== 200) {
        cartStore.setItemsInCart(0);
        return;
      }

      if (response && response.status === 200) {
        if (Array.isArray(response.data.products) && response.data.products.length) {
          const totalQuantity = calculateTotalQuantity(response.data.products);
          cartStore.setItemsInCart(totalQuantity);
        } else {
          cartStore.setItemsInCart(0);
        }
      }
    };
    fetchCart();

    const fetchCategoryData = async () => {
      const fieldData = 'Category.keyword';
      const data = await getMenuItems(fieldData);
      if (data && data.menuItems) {
        const categoryObjects = Object.values(data.menuItems)[0].buckets;
        const categories = categoryObjects.map(({ key }) => key);

        const categoryArray = categories.map((category, index) => ({
          id: index,
          name: category,
          href: `/category/${encodeURIComponent(category.toLowerCase())}`,
        }));

        const result = sortArrayByArray(categoryArray, [], 'name');

        documentDetails.categories = result;
        setDocumentDetails({ ...documentDetails });
      }
    };

    if (documentDetails.categories.length < 1) {
      fetchCategoryData();
    }

    const fetchProductData = async () => {
      const fieldData = 'DocumentType.keyword';
      const data = await getMenuItems(fieldData);
      if (data && data.menuItems) {
        const productObjects = Object.values(data.menuItems)[0].buckets;
        const products = productObjects.map(({ key }) => key);

        const productArray = products.map((product, index) => ({
          id: index,
          name: product,
          href: `/search?DocumentType=${encodeURIComponent(product)}`,
        }));

        const result = sortArrayByArray(
          productArray,
          ['ISSO-publicatie', 'Kennispaper', 'Checklist'],
          'name',
        );
        documentDetails.documentTypes = result;
        setDocumentDetails({ ...documentDetails });
      }
    };
    if (documentDetails.documentTypes.length < 1) {
      fetchProductData();
    }

    checkClaim();

    setHasMounted(true);
  }, [claims]);

  return (
    hasMounted && (
      <header>
        <div className="bg-primary">
          <div className="container mx-auto max-w-7xl px-4 xl:px-0">
            <div className="flex items-center justify-between py-3 lg:gap-20 lg:py-4">
              <div className="flex items-center justify-between gap-5">
                <div className="pt-2 lg:hidden">
                  {/* General mobile menu */}
                  <MobileMenu
                    setSidebarOpen={setSidebarOpen}
                    sidebarOpen={sidebarOpen}
                    Fragment={Fragment}
                    logoData={logoData}
                    documentDetails={documentDetails}
                  />

                  {/* Mobile side menu */}
                  <UserMobileMenu
                    profileBarOpen={profileBarOpen}
                    Fragment={Fragment}
                    setSidebarOpen={setSidebarOpen}
                    fullName={fullName}
                    setProfileBarOpen={setProfileBarOpen}
                  />
                </div>
                <Link href={'/'}>
                  <logoData.bouwzo.svg className="mb-1 w-20 fill-white md:w-24" />
                </Link>
              </div>
              {/* MD+ */}
              <div className="z-20 hidden w-full md:block">
                <SearchBox type="primary" />
              </div>
              {/* Row of icons on the right */}
              <div className="flex h-11 gap-2 md:ml-10 md:gap-2.5">
                {/* if application is in isLoggedIn state; render Dropdown, otherwise show LinkButton */}
                <div className="z-20 hidden md:block">
                  {isLoggedIn ? (
                    <Dropdown label={fullName} items={menuItems} isPrimary isLeft={false} />
                  ) : (
                    <Link href="/login">
                      <div className="grid h-11 place-content-center rounded-md md:border md:border-primary-dark/50">
                        <div className="flex gap-4 p-4">
                          <Icon iconName="user" color="text-primary-dark" />
                          <div className="font-secondary font-bold text-primary-dark">Inloggen</div>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
                <div className="block md:hidden">
                  {isLoggedIn ? (
                    <button onClick={() => setProfileBarOpen(true)}>
                      <div className="grid size-11 place-content-center rounded-md md:border md:border-primary-dark/50">
                        <Icon iconName="user" color="text-primary-dark" />
                      </div>
                    </button>
                  ) : (
                    <Link href="/login">
                      <div className="grid size-11 place-content-center rounded-md text-primary-dark md:border md:border-primary-dark/50">
                        <Icon iconName="user" color="text-primary-dark" />
                      </div>
                    </Link>
                  )}
                </div>
                <Link href="/cart">
                  <div className="relative grid size-11 place-content-center rounded-md md:border md:border-primary-dark/50">
                    <Icon iconName="cart" color="text-primary-dark" />
                    {itemsInCart > 0 ? (
                      <div className="absolute right-0 top-0 w-4 rounded-full bg-primary-dark text-center text-xs text-white">
                        {itemsInCart}
                      </div>
                    ) : null}
                  </div>
                </Link>

                <Link href="/favorite">
                  <div className="relative grid size-11 place-content-center rounded-md md:border md:border-primary-dark/50">
                    <Icon iconName="heart" color="text-primary-dark" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {/* Tertiary Bar MD- with box */}
          <div className="mt--2 flex items-center bg-primary pb-4 md:hidden">
            {/* MD- */}
            <div className="z-10 w-full pt-2">
              <SearchBox type="primary" />
            </div>
          </div>
          {/* Tertiary Bar LG+ with dropdowns */}
          <div className="hidden bg-primary lg:flex">
            <div className="container mx-auto max-w-7xl px-4 xl:px-0">
              <div className="flex grow-0 items-center gap-10 pb-1 font-black leading-5 text-primary-dark">
                <Link href="/">
                  <div className="font-primary">Home</div>
                </Link>
                <Dropdown
                  label="Categorieën"
                  items={documentDetails.categories}
                  isPrimary={false}
                  isLeft
                />
                <Dropdown
                  label="Producten"
                  items={documentDetails.documentTypes}
                  isPrimary={false}
                  isLeft
                />
                <Link href="https://over.bouwzo.nl" target="_blank">
                  <div className="pr-6 font-primary">Over BouwZo</div>
                </Link>
                <Link href="https://over.bouwzo.nl/nieuws-blogs/" target="_blank">
                  <div className="pr-6 font-primary">Nieuws</div>
                </Link>
                <Link href="/contact">
                  <div className="font-primary">Support</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  );
}
