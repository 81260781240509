import Icon from '@elements/Icon';
import Status from '@elements/Status';
import Button from '@elements/buttons/Button';
import LinkButton from '@elements/buttons/LinkButton';
import { Menu, Transition } from '@headlessui/react';
import { formatStripeCurrency } from '@helpers/formatValue';
import { getDate } from '@helpers/getDate';
import { useState } from 'react';

export default function OrganizationSubscriptionSummary({
  totalAmount,
  invoiceUrl,
  nextInvoiceDate,
  subscriptionStatus,
}) {
  // Initialize the open state variable and a function to toggle it
  const [isOpen, setIsOpen] = useState(false);

  function determineSubscriptionStatus(status = '') {
    if (status === 'ACTIVE') {
      return 'Actief';
    } else {
      return 'Inactief';
    }
  }

  return (
    <div className="flex items-center justify-between p-2 md:p-8">
      <div className="p-2 md:p-0">
        <Status variant={determineSubscriptionStatus(subscriptionStatus)} />
      </div>
      <div className="hidden md:block">
        <div className="font-secondary">Volgende factuurdatum</div>
        <div className="mt-1 font-secondary text-sm text-primary-dark/70">
          {getDate(nextInvoiceDate)}
        </div>
      </div>
      <div>
        <div className="font-secondary">Totaal bedrag</div>
        <div className="mt-1 text-right font-secondary text-sm">
          {formatStripeCurrency(totalAmount)}
        </div>
      </div>
      <div className="pr-2 md:pr-2 lg:hidden">
        <Menu as="div" className="relative inline-block text-left">
          <>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-gray-200/75"
            >
              <Icon iconName="menu" size="xl" color="text-primary-dark" />
            </button>

            <Transition
              show={isOpen}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              {(ref) => (
                <Menu.Items
                  static
                  className="absolute right-0 mt-1 w-32 divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                  ref={ref}
                >
                  <Menu.Item>
                    <a
                      href={invoiceUrl}
                      className="flex w-full cursor-pointer justify-between px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100"
                    >
                      Bekijk factuur
                    </a>
                  </Menu.Item>

                  <Menu.Item>
                    <button
                      type="button"
                      disabled
                      className="flex w-full cursor-not-allowed justify-between px-4 py-2 text-sm font-medium text-gray-400"
                    >
                      Bekijk details
                    </button>
                  </Menu.Item>
                </Menu.Items>
              )}
            </Transition>
          </>
        </Menu>
      </div>
      <div className="hidden gap-4 lg:flex">
        <Button buttonType="secondary" label="Bekijk details" disabled />
        <LinkButton
          buttonType="secondary"
          label="Bekijk factuur"
          href={invoiceUrl}
          target="_blank"
          disabled={!invoiceUrl}
        />
      </div>
    </div>
  );
}
