import LinkButton from '@elements/buttons/LinkButton';
import Container from '@elements/Container';
import Icon from '@elements/Icon';
import NextLink from '@elements/links/NextLink';
import logo from '@elements/Logo';
import { useEnvContext } from '@helpers/envProvider';
import { usePlausible } from 'next-plausible';

const data = {
  address: { title: 'Bezoekadres', details: 'Weena 505', detailsTwo: '3013 AL Rotterdam' },
  postAddress: {
    title: 'Postadres',
    details: 'BouwZo',
    detailsTwo: 'Postbus 2838',
    detailsThree: '3000 CV Rotterdam',
  },
  vatNumber: { title: 'BTW-nummer', details: 'NL818111240B01' },
  chamberOfCommerceNumber: { title: 'KvK-nummer', details: '24416420' },
  bankDetails: {
    title: 'Bankgegevens',
    details: 'IBAN: NL24 RABO 0189 4886 38',
    detailsTwo: 'BIC: RABONL2U',
  },
  socials: {
    title: 'Volg ons op',
    details: [
      { logo: 'twitter', href: 'https://twitter.com/issonieuws' },
      { logo: 'linkedin', href: 'https://nl.linkedin.com/company/isso' },
    ],
  },
  phone: '+ 31 (0)10 206 59 60',
  email: 'info@bouwzo.nl',
  newsletter: 'Aanmelden nieuwsbrief',
  links: [
    { label: 'Algemene voorwaarden', href: 'https://over.bouwzo.nl/algemene-voorwaarden/' },
    { label: 'Gebruiksvoorwaarden', href: 'https://over.bouwzo.nl/gebruiksvoorwaarden' },
    { label: 'Privacy', href: '/legal/privacy' },
    { label: 'Cookies', href: '/legal/cookies' },
    { label: 'Klantenservice', href: '/contact' },
  ],
};

function TextBlock({ title, lines = [] }) {
  return (
    <div className="flex flex-col">
      <div className="font-secondary font-black text-primary-dark">{title}</div>
      {lines.map((line, idx) => (
        <div key={idx} className="font-secondary text-primary-dark">
          {line}
        </div>
      ))}
    </div>
  );
}

function ContactLink({ icon, text, href }) {
  return (
    <a href={href} target="_blank" rel="noreferrer" className="flex items-center gap-4">
      <Icon iconName={icon} color="text-primary-dark" />
      <div className="font-secondary text-primary-dark underline">{text}</div>
    </a>
  );
}

function SocialButtons({ socials, onClick }) {
  return (
    <div className="flex gap-2">
      {socials?.map((social) => (
        <LinkButton
          key={social.logo}
          onClick={() => onClick(social.logo)}
          style={{ WebkitTapHighlightColor: 'transparent' }}
          buttonType="secondary"
          href={social.href}
          target="_blank"
          icon={social.logo}
          overrideClass="!rounded-full h-10 w-10 !text-primary !bg-primary-dark"
        />
      ))}
    </div>
  );
}

function FooterLinks({ links }) {
  return (
    <nav className="flex flex-col gap-2 font-secondary text-primary-dark underline">
      {links?.map((link) => (
        <NextLink key={link.href} href={link.href} scroll>
          {link.label}
        </NextLink>
      ))}
    </nav>
  );
}

export default function Footer() {
  const { environment } = useEnvContext();
  const { bouwzo } = logo;
  const plausibleEvent = usePlausible();
  const NEXT_PUBLIC_VERSION = environment['NEXT_PUBLIC_VERSION'];

  const handleSocialClick = (logo) => {
    if (logo === 'twitter') plausibleEvent('Twitter Share');
    if (logo === 'linkedin') plausibleEvent('LinkedIn Share');
  };

  return (
    <Container variant="primary">
      <footer>
        {/* SM */}
        <div className="flex flex-col gap-4 bg-primary py-6 md:hidden md:px-4">
          <bouwzo.svg className="w-[72px] text-primary-dark" />
          <TextBlock
            title={data.address.title}
            lines={[data.address.details, data.address.detailsTwo]}
          />
          <TextBlock
            title={data.postAddress.title}
            lines={[
              data.postAddress.details,
              data.postAddress.detailsTwo,
              data.postAddress.detailsThree,
            ]}
          />
          <TextBlock title={data.vatNumber.title} lines={[data.vatNumber.details]} />
          <TextBlock
            title={data.chamberOfCommerceNumber.title}
            lines={[data.chamberOfCommerceNumber.details]}
          />
          <TextBlock
            title={data.bankDetails.title}
            lines={[data.bankDetails.details, data.bankDetails.detailsTwo]}
          />
          <ContactLink icon="phone" text={data.phone} href={`tel:${data.phone}`} />
          <ContactLink icon="mail" text={data.email} href={`mailto:${data.email}`} />
          <ContactLink
            icon="newspaper"
            text={data.newsletter}
            href="https://over.bouwzo.nl/inschrijven-nieuwsbrief/"
          />
          <div className="flex flex-col">
            <div className="pb-2 font-primary font-bold text-primary-dark">
              {data.socials.title}
            </div>
            <SocialButtons socials={data.socials.details} onClick={handleSocialClick} />
          </div>
          <hr className="border-primary-dark/70" />
          <FooterLinks links={data.links} />
          <div className="font-secondary text-sm font-light text-primary-dark">
            Versie: {NEXT_PUBLIC_VERSION}
          </div>
        </div>

        {/* LG */}
        <div className="hidden flex-col bg-primary md:flex md:py-7 lg:pt-14">
          <bouwzo.svg className="w-[72px] pb-8 text-primary-dark" />
          <div className="flex md:gap-24 lg:justify-between">
            <div className="flex flex-col justify-between gap-6">
              <TextBlock
                title={data.address.title}
                lines={[data.address.details, data.address.detailsTwo]}
              />
              <div className="flex pb-4">
                <ContactLink icon="phone" text={data.phone} href={`tel:${data.phone}`} />
              </div>
            </div>
            <div className="flex flex-col justify-between gap-8">
              <TextBlock
                title={data.postAddress.title}
                lines={[
                  data.postAddress.details,
                  data.postAddress.detailsTwo,
                  data.postAddress.detailsThree,
                ]}
              />
              <div className="flex pb-4">
                <ContactLink icon="mail" text={data.email} href={`mailto:${data.email}`} />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <TextBlock title={data.vatNumber.title} lines={[data.vatNumber.details]} />
              <TextBlock
                title={data.chamberOfCommerceNumber.title}
                lines={[data.chamberOfCommerceNumber.details]}
              />
              <div className="flex pb-4">
                <ContactLink
                  icon="newspaper"
                  text={data.newsletter}
                  href="https://over.bouwzo.nl/inschrijven-nieuwsbrief/"
                />
              </div>
            </div>
            <TextBlock
              title={data.bankDetails.title}
              lines={[data.bankDetails.details, data.bankDetails.detailsTwo]}
            />
            <div className="hidden flex-col lg:flex">
              <div className="pb-4 font-primary text-lg font-black text-primary-dark">
                {data.socials.title}
                <div className="flex gap-4 pt-2">
                  <SocialButtons socials={data.socials.details} onClick={handleSocialClick} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-col py-8 md:flex lg:hidden">
            <div className="pb-4 font-bold text-primary-dark">{data.socials.title}</div>
            <div className="flex gap-4 ">
              <SocialButtons
                socials={data.socials.details}
                onClick={(logo) => plausibleEvent(logo === 'twitter' ? 'Twitter' : 'LinkedIn')}
              />
            </div>
          </div>
          <hr className="border-primary-dark/70" />
          <div className="flex justify-between">
            <div className="flex pt-2 font-secondary text-primary-dark underline md:gap-6 lg:gap-32">
              {data.links?.map((link) => (
                <NextLink key={link.href} href={link.href}>
                  {link.label}
                </NextLink>
              ))}
            </div>
            <div className="pt-3 font-secondary text-sm font-light text-primary-dark">
              Versie: {NEXT_PUBLIC_VERSION}
            </div>
          </div>
        </div>
      </footer>
    </Container>
  );
}
